import React, { useState, useEffect } from "react"
import Lottie from 'react-lottie'
import VizSensor from "react-visibility-sensor"
import Goto from "./goto"
const AnimationTextLeft = props => {
  const [state, setState] = useState({
    isStopped: true,
    inView: false,
    animation: "",
    isLoading: false,
  })
  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: state.animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
      progressiveLoad: true,
      className: `kuda-phoneDebit--illustration ${props.leftClassName}`,
    },
  }
  useEffect(() => {
    import(
      `../../../../assets/animations/${props.animationUrl}/data.json`
    ).then(background => {
      setState(prevState => ({
        ...prevState,
        animation: background,
        isStopped: true,
      }))
    })
  }, [])
  return (
    <div className="kuda-section kuda-below--animation">
      <div className="kuda-section--inner flex justify-between align-center">
        <div className="kuda-section--45">
          <div className="kuda-section--wrap">
            <h2 className="kuda-section--heading text-xlbold color-primary title-bottom--spacing">
              {props.title}
            </h2>
            <p className="color-black kuda-section--max">{props.subtitle}</p>
            {typeof props.buttonName !== "undefined" &&
              (typeof props.isExternal !== "undefined" ? (
                <Goto
                  to={props.buttonUrl}
                  name={props.buttonName}
                  isExternal={true}
                />
              ) : (
                <Goto to={props.buttonUrl} name={props.buttonName} />
              ))}
          </div>
        </div>
        <div className="kuda-section--55">
          <VizSensor
            onChange={() => {
              setState(prevState => ({
                ...prevState,
                isStopped: false,
              }))
            }}
            partialVisibility={true}
            offset={{ bottom: 230 }}
          >
            <div className="kuda-section--illustration">
              {state.animation !== "" ? (
                <Lottie options={defaultOptions} isStopped={state.isStopped} />
              ) : (
                props.fallback
              )}
            </div>
          </VizSensor>
        </div>
      </div>
    </div>
  )
}
export default AnimationTextLeft